import '@/assets/index.scss';
import type { Theme } from 'vitepress';
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import { createPinia } from 'pinia';
import { defineAsyncComponent, hydrateOnVisible } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VTooltip = defineAsyncComponent({
  loader: () => import('UiKit/components/VTooltip/VTooltip.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VButton = defineAsyncComponent({
  loader: () => import('UiKit/components/VButton/VButton.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VSvgIcon = defineAsyncComponent({
  loader: () => import('UiKit/components/VSvgIcon/VSvgIcon.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VTag = defineAsyncComponent({
  loader: () => import('UiKit/components/VTag/VTag.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VSliderWithCardBlueLine = defineAsyncComponent({
  loader: () => import('UiKit/components/Sliders/VSliderWithCardBlueLine.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});


export default {
  Layout: AppLayoutDefault,
  enhanceApp({ app, router, siteData }) {
    const pinia = createPinia();
    app.use(pinia);
    app.component('VTooltip', VTooltip);
    app.component('VButton', VButton);
    app.component('VSvgIcon', VSvgIcon);
    app.component('VTag', VTag);
    app.component('VSliderWithCardBlueLine', VSliderWithCardBlueLine);
  }
} satisfies Theme
