<!-- eslint-disable @typescript-eslint/no-unsafe-assignment -->
<script setup lang="ts">
import {
  computed, defineAsyncComponent, ref, onMounted,
} from 'vue';
import { useData, useRoute } from 'vitepress';

// monkey patch console.error to find import errors

const ViewHome = defineAsyncComponent(() => import('@/layouts/Home/ViewHome.vue'));
const TagList = defineAsyncComponent(() => import('@/layouts/Tag/TagList.vue'));
const ViewEventDetail = defineAsyncComponent(() => import('@/layouts/Event/ViewEventDetail.vue'));
const ViewUpdatesRedirect = defineAsyncComponent(() => import('@/layouts/Updates/ViewUpdatesRedirect.vue'));
const AuthorDetail = defineAsyncComponent(() => import('@/layouts/Author/Detail.vue'));
const ViewArchive = defineAsyncComponent(() => import('@/layouts/Archive/ViewArchive.vue'));
const ViewPageDetail = defineAsyncComponent(() => import('@/layouts/Page/ViewPageDetail.vue'));
const CourseList = defineAsyncComponent(() => import('@/layouts/Course/CourseList.vue'));
const CourseDetail = defineAsyncComponent(() => import('@/layouts/Course/CourseDetail.vue'));
const VideoList = defineAsyncComponent(() => import('@/layouts/Video/VideoList.vue'));
const VideoDetail = defineAsyncComponent(() => import('@/layouts/Video/VideoDetail.vue'));
const ViewBookDetail = defineAsyncComponent(() => import('@/layouts/Book/ViewBookDetail.vue'));
const ViewErrorNotFound404 = defineAsyncComponent(() => import('@/layouts/Errors/ViewErrors.vue'));

const { page, frontmatter, theme } = useData();
import { data } from '@/store/all.data';
const allPages = computed(() => (
  data
));
import { convertPages } from 'UiKit/types/pages';
convertPages(allPages.value);

onMounted(() => {
  // console.log(window);
  const inIframe = window.self !== window.top;
  if (inIframe === false) {
    if (theme.value.env.redirect == true && window.location.search != "?noredirect") {
      window.location = theme.value.env.mainDomain + window.location.pathname + window.location.hash;
    }
  }

  let pushState = history.pushState;
  history.pushState = function () {
    pushState.apply(history, arguments);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    window.parent.postMessage({
      action: 'scrollTo',
      data: { top: 200, behavior: 'smooth' },
    }, "*");

    if (typeof window !== 'undefined') {
      const err = console.error;
      console.error = function() {
        // /assets/chunks/ViewErrors.B98_KOXD.js
        if (arguments.length > 0) {
            const msg = arguments[0].toString();
            if (
              msg.indexOf('Failed to fetch dynamically imported module:') !== -1 &&
              msg.indexOf('/assets/chunks/') !== -1 &&
              msg.indexOf('.js') !== -1) {
                window.location.reload();
            }
        }
      }
      // console.log(arguments);
      err(arguments);
    };

    // update url on NextTick
    setTimeout(() => {
      window.parent.postMessage({
        action: 'updatePage',
        data: {
          url: window.location.pathname,
        },
      }, "*");
    }, 0);

    for(let i = 1; i < 8; i += 2) {
      setTimeout(() => {
        const mainApp = document.querySelector('.app-layout-default__main');
        if (mainApp) {
          window.parent.postMessage({
            action: 'updatePage',
            data: {
              height: mainApp.offsetHeight,
              title: window.document.title,
            },
          }, "*");
        }
      }, 100 * i);
    }
  };

  // update page height
  window.onpopstate = function(event) {
    // console.log('onpopstate', event);
    const mainApp = document.querySelector('.app-layout-default__main');
    if (mainApp) {
      window.parent.postMessage({
        action: 'updatePage',
        data: { 
          height: mainApp.offsetHeight + 100,
          hash: window.location.hash,
        },
      }, "*");
    }
  };

  // capture hash links
  window.document.onclick = (ev) => {
    if (window.location.hash.length > 0) {
      window.parent.postMessage({
        action: 'updatePage',
        data: { 
          hash: window.location.hash,
        },
      }, "*");
    }
  }

  /*
  // disable right click for now
  window.oncontextmenu = function () {
    return false;
  }
  */

  /*
  window.navigation.addEventListener("navigate", (event) => {
    console.log(event.destination);
    if (event.destination) {
      if (window.navigation.currentEntry.url.split('#')[0] != event.destination.url.split('#')[0]) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        window.parent.postMessage({ action: 'scrollTo', data: { top: 0, behavior: 'smooth' }}, "*");
      }
    }
  });
  */
});
</script>


<template>
  <div class="AppLayoutDefault app-layout-default">
    <main class="app-layout-default__main is--markdown">
      <ViewErrorNotFound404
        v-if="page.isNotFound"
      />
      <ViewHome v-else-if="frontmatter.layout === 'home'" />
      <ViewPageDetail
        v-else-if="frontmatter.layout === 'page-detail'"
      />
      <ViewEventDetail
        v-else-if="frontmatter.layout === 'event-detail'"
      />
      <TagList
        v-else-if="frontmatter.layout === 'tag-list'"
      />
      <ViewUpdatesRedirect
        v-else-if="frontmatter.layout === 'news-updates'"
      />
      <AuthorDetail
        v-else-if="frontmatter.layout === 'author-detail'"
      />
      <ViewBookDetail
        v-else-if="frontmatter.layout === 'book-detail'"
      />
      <CourseList
        v-else-if="frontmatter.layout === 'course-list'"
      />
      <CourseDetail
        v-else-if="frontmatter.layout === 'course-detail'"
      />
      <VideoList
        v-else-if="frontmatter.layout === 'video-list'"
      />
      <VideoDetail
        v-else-if="frontmatter.layout === 'video-detail'"
      />
      <ViewArchive
        v-else-if="frontmatter.layout === 'archives'"
      />
      
      <div
        v-else
        class="is--content is--container is--page"
      >
        <Content />
        <!-- if we use somewhere Content it is mandatory to wrap it in container with class 'is--markdown'-->
      </div>
    </main>
  </div>
</template>


<style lang="scss">

.app-layout-default {
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 100;
    height: $header-height;
    transition: all .12s ease-in-out;

    &.fixed {
      position: fixed;
      top: 0;
      background: $white;
      box-shadow: $box-shadow-small;
    }
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    z-index: 1;
    background: $white;
  }

  .is--content {
    width: 100%;
  }

  &__footer {
    width: 100%;
    z-index: 1;
    margin-top: 0 !important;
  }
}
</style>
